import React from 'react'
import styles from '../styles/Navbar.module.css'

const Cover = ({imagePath}) => {

  return (
    <img src={imagePath} alt='cover' className={styles.cover}></img>
  )
}

export default Cover