import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/ContactAndLocation.module.css'

const GeneralInformation = () => {
  return (
    <>
      <div className={styles.textWrapper}>
        <img src={`${process.env.PUBLIC_URL}/logo-mobile.svg`} alt='logo' className={styles.logo}></img>
        <Link className={styles.links} to='https://www.google.com/maps/place/Ul.+Theodorea+Roosevelta+15,+21000,+Split/@43.5033524,16.4485404,17z/data=!3m1!4b1!4m6!3m5!1s0x133560a819979bf9:0xce0f230948b3b9c5!8m2!3d43.5033485!4d16.4511153!16s%2Fg%2F11c27g553t?entry=ttu'>
                    Ulica Theodorea Roosevelta 15, 21000 Split</Link>
        <a href='tel:+385911222234' className={styles.links}>Tel. +385 91 122 22 34</a>
        <a href='mailto:palaceschonmilesi@gmail.com' className={`${styles.links} ${styles.email}`}>palaceschonmilesi@gmail.com</a>
        </div>
    </>
  )
}

export default GeneralInformation