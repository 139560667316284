import React from 'react'
import Navbar from '../components/Navbar'
import Cover from '../components/Cover'
import GeneralInformation from '../components/GeneralInformation'
import Footer from '../components/Footer'
import styles from '../styles/Photogallery.module.css'
import content from '../data/photos.json'
import PhotoModal from '../components/PhotoModal';
import { useState } from 'react';

const Photogallery = () => {
    const photos = content.photos;

    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const openModal = (photo) => {
        setSelectedPhoto(photo);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };
  return (
    <>
    <Navbar />
    <Cover imagePath={`${process.env.PUBLIC_URL}/photogallery-cover.jpg`}/>
    <div className={styles.headingAndDescriptionWrapper}>
        <h1 className={styles.text}>Photogallery</h1>
        <h2 className={`${styles.text} ${styles.nameOfPalace}`}>Palace Schön Milesi</h2>
    </div>
    <div className={styles.photosWrapper}>
        {photos.map((photo) => (
            <div key={photo.id} className={styles.photoWrapper} onClick={() => openModal(photo)}>
                <img src={`${process.env.PUBLIC_URL}`+ photo.src} alt={photo.alt} className={styles.image}></img>
            </div>
        ))}
    </div>
    {selectedPhoto && <PhotoModal photos={photos} selectedPhoto={selectedPhoto} onClose={closeModal} />}
    <GeneralInformation />
    <Footer />
    </>
  )
}

export default Photogallery
