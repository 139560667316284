import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ContactAndLocation from './pages/ContactAndLocation';
import './App.css';
import Accomodation from './pages/Accomodation';
import RoomDetail from './components/RoomDetail';
import Photogallery from './pages/Photogallery';
import NotFound from './pages/NotFound';
import EatAndDrink from './pages/EatAndDrink';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/Accomodation' exact element={<Accomodation />} />
        <Route path="/room/:id" element={<RoomDetail />} />
        <Route path='/EatAndDrink' exact element={<EatAndDrink />} />
        <Route path='/Photogallery' exact element={<Photogallery />} />
        <Route path='/ContactAndLocation' exact element={<ContactAndLocation />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
