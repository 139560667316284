import React from 'react'
import content from '../data/rooms.json'
import { Link } from 'react-router-dom';
import Cover from '../components/Cover';
import Navbar from '../components/Navbar';
import styles from '../styles/Accomodation.module.css'
import GeneralInformation from '../components/GeneralInformation';
import Footer from '../components/Footer';

const Accomodation = () => {
  const rooms = content.rooms;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar /> 
      <Cover imagePath={`${process.env.PUBLIC_URL}/accomodation-cover.jpg`}/>
      <div className={styles.textWrapper}>
        <h1 className={styles.text}>Accomodation</h1>
        <h2 className={`${styles.text} ${styles.nameOfPalace}`}>Palace Schön Milesi</h2>
      </div>
      <div className={styles.roomsWrapper}>
        {rooms.slice(0,3).map((room) => ( /* Only show the first 3 rooms */
          <div key={room.id} className={styles.roomDetailsWrapper}>
            <Link to={`/room/${room.id}`} onClick={scrollToTop}><img src={room.imageSrc} alt={room.imageDescription} className={styles.image}></img></Link>
            <h1 className={styles.name}>{room.name}</h1>
            <p className={styles.description}>{room.shortDescription}</p>
            <Link to={`/room/${room.id}`} onClick={scrollToTop} className={styles.buttonLink}><button className={styles.button}>Details</button></Link>
          </div>
        ))}
      </div>
      <GeneralInformation />
      <Footer />
    </div>
  )
}

export default Accomodation