import React from 'react'
import styles from '../styles/Navbar.module.css'
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToContactForm = () => {
    window.scrollTo({ top: 1150, behavior: 'smooth' });
  };

  const scrollToContactFormMobile = () => {
    window.scrollTo({ top: 800, behavior: 'smooth' });
  };

  const navigate = useNavigate();

  const handleItemClick = (path) => {
    removeActive();
    scrollToTop();
    navigate(path);
  };

  return (
    <nav>
        <div className={styles.navbarContainer}>
          
          <div className={`${styles.circle} ${styles.enquiry}`}>
            <Link to='/ContactAndLocation' className={styles.navRoundLink} onClick={scrollToContactForm}>
              <div className={styles.circle2}>
                ENQUIRY
              </div>
            </Link>
          </div>

          <ul className={styles.navMenuDesktop}>
              <li>
                <Link to='/Accomodation' className={styles.navLink} onClick={scrollToTop}>ACCOMODATION</Link>
              </li>
              <li>
                <Link to='/EatAndDrink' className={styles.navLink} onClick={scrollToTop}>EAT & DRINK</Link>
              </li>
              <Link to='/' onClick={scrollToTop}><img src={`${process.env.PUBLIC_URL}/logo-desktop.svg`} alt='logo' className={styles.desktopLogo}></img></Link>
              <li>
                <Link to='/Photogallery' className={styles.navLink} onClick={scrollToTop}>PHOTOGALLERY</Link>
              </li>
              <li>
                <Link to='/ContactAndLocation' className={styles.navLink} onClick={scrollToTop}>CONTACT & LOCATION</Link>
              </li>
          </ul>

          <div className={`${styles.circle} ${styles.rates}`}>
              <Link to='/Accomodation' className={styles.navRoundLink} onClick={scrollToTop}> 
                <div className={styles.circle2}>RATES</div>
              </Link>  
            </div>

          <Link to='/' onClick={scrollToTop}><img src={`${process.env.PUBLIC_URL}/logo-mobile.svg`} alt='logo' className={styles.mobileLogo}></img></Link>
          <img src={`${process.env.PUBLIC_URL}/hamburger-menu.svg`} alt='hamburger-menu' onClick={toggleActiveClass} className={styles.hamburger}></img>
        

          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={() => handleItemClick('/Accomodation')}>
              <span className={styles.navLink}>ACCOMMODATION</span>
            </li>
            <li onClick={() => handleItemClick('/EatAndDrink')}>
              <span className={styles.navLink}>EAT & DRINK</span>
            </li>
            <li onClick={() => handleItemClick('/Photogallery')}>
              <span className={styles.navLink}>PHOTOGALLERY</span>
            </li>
            <li onClick={() => handleItemClick('/ContactAndLocation')}>
              <span className={styles.navLink}>CONTACT & LOCATION</span>
            </li>
          </ul>

         
          <div className={styles.ctaMobile}>
            <div className={styles.item}>
              <Link to='/ContactAndLocation' className={styles.ctaLink} onClick={scrollToContactFormMobile}>
                <button className={styles.button}>ENQUIRY</button>
              </Link>
            </div>

            <div className={styles.item}>
              <Link to='/Accomodation' className={styles.ctaLink} onClick={scrollToTop}>
                <button className={styles.button}>RATES</button>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.contactInfoMobile}>
            <ul>
              <Link className={styles.links} to='https://www.google.com/maps/place/Ul.+Theodorea+Roosevelta+15,+21000,+Split/@43.5033524,16.4485404,17z/data=!3m1!4b1!4m6!3m5!1s0x133560a819979bf9:0xce0f230948b3b9c5!8m2!3d43.5033485!4d16.4511153!16s%2Fg%2F11c27g553t?entry=ttu'>
                <li>
                  <img src={`${process.env.PUBLIC_URL}/location.svg`} alt='location' className={styles.contactIcon}></img>
                </li>
              </Link>
              <a href='mailto:palaceschonmilesi@gmail.com' className={`${styles.links} ${styles.email}`}>
                <li>
                  <img src={`${process.env.PUBLIC_URL}/mail.svg`} alt='mail' className={styles.contactIcon}></img>
                </li>
              </a>
              <a href='tel:+385911222234' className={styles.links}>
                <li>
                  <img src={`${process.env.PUBLIC_URL}/phone.svg`} alt='phone' className={styles.contactIcon}></img>
                </li>
              </a>
            </ul>
        </div>
    </nav>
  )
}

export default Navbar