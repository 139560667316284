import React from 'react'
import roomsData from '../data/rooms.json'
import { useParams } from 'react-router-dom';
import styles from '../styles/RoomDetail.module.css'
import Navbar from './Navbar';
import Cover from './Cover';
import { Link } from 'react-router-dom';
import GeneralInformation from './GeneralInformation';
import Footer from './Footer';

const RoomDetail = () => {
  const rooms = roomsData.rooms;
  const {id} = useParams();
  const roomId = parseInt(id);
  const room = rooms.find(item => item.id === String(roomId));

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // if (!room) {
  //   return <div>Room not found.</div>;
  // }
  return (
    <>
    <Navbar />
    <Cover imagePath={`${process.env.PUBLIC_URL}/rooms-gallery/room${room.id}-main.jpg`}/>
    <div className={styles.headingAndDescriptionWrapper}>
      <h1 className={styles.text}>{room.name}</h1>
      <h2 className={`${styles.text} ${styles.nameOfPalace}`}>Palace Schön Milesi</h2>
      <h3 className={styles.longDescription}>{room.longDescription}</h3>
    </div>
    <div className={styles.imageWrapper}>
      <img src={`${process.env.PUBLIC_URL}/rooms-gallery/room${room.id}-second.jpg`} alt={room.imageDescription} className={styles.image}></img>
    </div>
    <div className={styles.specWrapper}>
      <div className={styles.specs}>
        <h4 className={styles.title}>Room Spec</h4>
          <p className={styles.roomSpecs}>Size: {room.size}</p>
          <p className={styles.roomSpecs}>View: {room.view}</p>
          <p className={styles.roomSpecs}>Max Occupancy: {room.maxOccupancy}</p>
      </div>
      <div className={styles.specs}>
        <h4 className={styles.title}>Features</h4>
          <div className={styles.featuresWrapper}>
            <ul className={styles.list}>
              {room.features.map((feature, index) => (
                <li key={index} className={styles.feature}>{feature}</li>
              ))}
            </ul>
          </div>
      </div>
    </div>
      <div className={styles.buttonWrapper}>
        <Link to='/' className={styles.buttonLink}><button className={styles.button}>Check Availability</button></Link>
      </div>
    
    <div className={styles.imageWrapper}>
      <img src={`${process.env.PUBLIC_URL}/rooms-gallery/room${room.id}-third.jpg`} alt={room.imageDescription} className={styles.image}></img>
    </div>
    <h3 className={styles.exploreText}>Explore the other</h3>
    <div className={styles.buttonWrapper}>
      <Link to='/Accomodation' className={styles.buttonLink}><button className={styles.button} onClick={scrollToTop}>Rooms And Suits</button></Link>
    </div>
    <GeneralInformation />
    <Footer />
    </>
  )
}

export default RoomDetail