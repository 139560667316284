import React from 'react'
import Navbar from '../components/Navbar'
import styles from '../styles/ContactAndLocation.module.css'
import ContactForm from '../components/ContactForm'
import Cover from '../components/Cover'
import GeneralInformation from '../components/GeneralInformation'
import Footer from '../components/Footer'

const ContactAndLocation = () => {
  return (
    <>
    <Navbar />
    <Cover imagePath={`${process.env.PUBLIC_URL}/contact-cover.jpg`}/>
    <div className={styles.textWrapper}>
        <h1 className={styles.cAl}>Contact & Location</h1>
        <h2 className={`${styles.cAl} ${styles.nameOfPalace}`}>Palace Schön Milesi</h2>
        <GeneralInformation />
        <ContactForm />
    </div>
    <img src='./bacvice-landing-page.jpg' alt='bacvice-location' className={styles.locationImage}></img>
    <GeneralInformation />
    <Footer />
    </>
  )
}

export default ContactAndLocation