import React from 'react'
import styles from '../styles/ContactForm.module.css'
import { Link } from 'react-router-dom'

const ContactForm = () => {
  return (
    <div id='contact-form' className={styles.formContainer}>
        <h3 className={styles.info}>Info & Reservations</h3>
        <p className={styles.availability}>Availability, rates and online reservations:</p>
        <a href='https://www.booking.com/hotel/hr/bed-and-breakfast-jagoda-palace.en-gb.html' target="_blank" rel='noreferrer' className={styles.link}><button className={styles.button}><img src='./calendar.svg' alt='calendar-icon' className={styles.calendar}></img>CHECK NOW</button></a>
        <p className={styles.availability}>For inquiries and reservations feel free to contact us at <br></br></p>
        <a href='mailto:palaceschonmilesi@gmail.com' className={styles.link}>palaceschonmilesi@gmail.com</a>
        <p>or<br></br></p>
        <a href='tel:+385911222234' className={styles.link}>+385 91 122 22 34</a>
        <p>or fill up the form</p>

        <form className={styles.form} action="https://formspree.io/f/xnqklkke" method="POST">
          <div className={styles.inputContainer}>
            <input type='text' placeholder='Name*' name='name' className={styles.input}></input>
            <input type='text' placeholder='Surname*' name='surname' className={styles.input}></input>
            <input type='text' placeholder='E-mail*' name='email' className={styles.input}></input>
            <input type='text' placeholder='Phone*' name='phone' className={styles.input}></input>
          </div>
            <textarea placeholder='Message' name='message' className={styles.textArea}></textarea>
            <button className={styles.button} type='submit'><img src='./email.svg' alt='email-icon' className={styles.email}></img>SEND REQUEST</button>
        </form>
    </div>
  )
}

export default ContactForm