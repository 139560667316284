import React from 'react'
import styles from '../styles/Footer.module.css'

const Footer = () => {
  return (
    <>
    <footer className={styles.footerContainer}>
        <p className={styles.text}>Copyright © 2023 Palace Schön Milesi. All rights reserved.</p>
        <img src={`${process.env.PUBLIC_URL}/credit-cards.svg`} alt='credit-cards'></img>
    </footer>
    </>
  )
}

export default Footer