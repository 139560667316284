import React from 'react'
import Navbar from '../components/Navbar'
import Cover from '../components/Cover'
import Footer from '../components/Footer'
import GeneralInformation from '../components/GeneralInformation'
import styles from '../styles/EatAndDrink.module.css'

const EatAndDrink = () => {
  return (
    <>
    <Navbar /> 
    <Cover imagePath={`${process.env.PUBLIC_URL}/eat-and-drink-cover.jpg`}/>
    <div className={styles.textWrapper}>
      <h1 className={styles.text}>Eat & Drink</h1>
      <h2 className={`${styles.text} ${styles.nameOfPalace}`}>Palace Schön Milesi</h2>
      <p className={styles.description}>As our guest you are welcomed to our charming dining room. Also, feel free to indulge in the serene ambiance of our garden, where you can not only relax by the pool but also enjoy a delightful outdoor dining experience. In relaxing atmosphere you can enjoy in various choices of food, freshly seasonal fruits and vegetables from the countryside or homemade desserts.</p>
    </div>
    <div className={styles.imagesWrapper}>
        <img src={`${process.env.PUBLIC_URL}/eat-and-drink-1.jpg`} alt='eat-and-drink-1' className={styles.image}></img>
        <img src={`${process.env.PUBLIC_URL}/eat-and-drink-2.jpg`} alt='eat-and-drink-2' className={styles.image}></img>
        <img src={`${process.env.PUBLIC_URL}/eat-and-drink-3.jpg`} alt='eat-and-drink-3' className={styles.image}></img>
        <img src={`${process.env.PUBLIC_URL}/eat-and-drink-4.jpg`} alt='eat-and-drink-4' className={styles.image}></img>
    </div>
    <GeneralInformation />
    <Footer />
    </>
  )
}

export default EatAndDrink