import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import GeneralInformation from '../components/GeneralInformation';
import Footer from '../components/Footer';
import styles from '../styles/Home.module.css';

function Home() {
  return (
    <>
      <Navbar />
      <Cover imagePath={`${process.env.PUBLIC_URL}/main-cover.jpg`}/>
      <div className={styles.textWrapper}>
        <h2 className={styles.heading}>Palace Schön Milesi</h2>
        <p className={styles.text}>Palace Schön Milesi is a private villa, situated in a quiet residential area of Bačvice in Split. Palace was built in 1928. Today, it is completely renovated according to modern standards but has kept its’ original pieces of history in order to offer you a touch of luxury and comfort. Palace Schön Milesi, a luxury villa, offers an outdoor swimming pool with a sun terrace and is surrounded with a private flower garden.</p>
      </div>
      <img src='./bacvice-landing-page.jpg' alt='bacvice-location' className={styles.locationImage}></img>
      <GeneralInformation />
      <Footer />
    </>
  );
}

export default Home;