import React, { useState, useEffect, useCallback } from 'react';
import styles from '../styles/PhotoModal.module.css';

const PhotoModal = ({ photos, selectedPhoto, onClose }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // Update the current index when the selected photo changes
  useEffect(() => {
    const index = photos.findIndex((photo) => String(photo.id) === String(selectedPhoto.id));
    setCurrentPhotoIndex(index >= 0 ? index : 0);
  }, [selectedPhoto, photos]);

  // Wrap the functions in useCallback to memoize them
  const handlePrevClick = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentPhotoIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1));
    },
    [photos]
  );

  const handleNextClick = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentPhotoIndex((prevIndex) => (prevIndex < photos.length - 1 ? prevIndex + 1 : 0));
    },
    [photos]
  );

  // Add event listeners for arrow keys
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        handlePrevClick(e);
      } else if (e.key === 'ArrowRight') {
        handleNextClick(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handlePrevClick, handleNextClick]);

  return (
    <div className={styles.modal} onClick={onClose}>
      <span className={styles.close} onClick={onClose}>
        &times;
      </span>
      <img src={`${process.env.PUBLIC_URL}${photos[currentPhotoIndex].src}`} alt={photos[currentPhotoIndex].alt} />
      <div className={styles.navigation}>
        <button className={styles.prev} onClick={handlePrevClick}>
          &lt;
        </button>
        <button className={styles.next} onClick={handleNextClick}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default PhotoModal;
